import { useQuery } from '@tanstack/vue-query'
import type { ClaimStatus } from '@torticity/types'

type ClaimStatusResponse = {
  id: string
  status: ClaimStatus
  statuses: ClaimStatus[]
}

export function useClaimStatusQuery() {
  const { $http, $auth } = useNuxtApp()
  const $analytics = useAnalytics()
  const getClaim: () => Promise<ClaimStatusResponse> = () => {
    return $http.$get(`/v1/claims/${$auth.user?.lead.id}`)
  }

  if ($auth.user && process.client) {
    $analytics.identify($auth.user.id, {
      leadId: $auth.user.lead.id,
      email: $auth.user.email,
      firstName: $auth.user.firstName,
      lastName: $auth.user.lastName,
    })
  }

  return useQuery({
    queryFn: getClaim,
    queryKey: ['claim'],
  })
}
